import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import style from './button.module.scss'


const Button = ({ to, icon, children }) => (
	<Link to={to} className={style.button} role="button">
		<span>{children}</span>
		<div>
			<i className="fa">{icon}</i>
		</div>
	</Link>
)

Button.propTypes = {
	to: PropTypes.string,
	children: PropTypes.node
}

Button.defaultProps = {
	to: ``,
}

export default Button
