import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"

import Box from '../components/box'
import Button from '../components/button'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Post = ({ data }) => {
	const post = data.contentfulBlog

	return (
		<Layout pageTitle="Yo.">
			<SEO title={`${post.title} | Blog`} />

			<Button to="/b" icon="&#8592;">back</Button>

			<Box
				time={post.createdAt}
				title={post.title} >
				<div dangerouslySetInnerHTML={{ __html: post.post.childMarkdownRemark.html }} />
			</Box>
		</Layout>
	)
}

Post.propTypes = {
	data: PropTypes.object.isRequired
}

export default Post


export const query = graphql`
	query post($slug: String!) {
		contentfulBlog(slug: { eq: $slug }) {
			title
			createdAt(formatString: "DD MMMM, YYYY")
			post {
				childMarkdownRemark {
					html
				}
			}
		}
	}
`
