import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'

import Header from './header'

import './layout.scss'
import layout from './layout.module.scss'

const Layout = ({ children, pageTitle }) => (
	<StaticQuery
		query={graphql`
			query {
				site {
					siteMetadata {
						title
						author
					}
				}
			}
		`}
		render={data => (
			<div className={layout.body}>
				<Header siteTitle={data.site.siteMetadata.title} />
				<main><div className="container">{children}</div></main>
				<footer>
					<div className="container">
						<div className={layout.footer}>
							<nav>
								<Link to={`/a`}>about</Link>
								<Link to={`/b`}>blog</Link>
								<a href={`https://pinboard.in/u:leekiernan`}>links</a>
							</nav>
							<p>13S7GekXkv4jb28h45PRBq8AojAop8LQuM</p>
							<small>&copy;{new Date().getFullYear()}</small>
						</div>
					</div>
				</footer>
			</div>
		)}
	/>
)

Layout.propTypes = {
	pageTitle: PropTypes.string,
	children: PropTypes.node.isRequired,
}

export default Layout

