import React from 'react'

import style from './box.module.scss'

const Box = ({time, title, subtitle, url, children}) => {
  let header = url ?
  (title && <a href={url}><h2 className={style.title}>{title}{subtitle && `, `}<span>{subtitle}</span></h2></a>) :
  (title && <h2 className={style.title}>{title}{subtitle && `, `}<span>{subtitle}</span></h2>)

  return (
    <div className={style.box}>
      <time className={style.time}>{time}</time>
      { header }
      <div className={style.body}>{children}</div>
    </div>
  )
}

export default Box
