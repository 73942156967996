import { Link } from 'gatsby'
// import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Monogram from './monogram'

import style from './header.module.scss'

const Header = () => (
	<header className={style.header}>
		<div className="container">
			<Link to="/" aria-label="Monogram logo">
				<figure style={{ maxWidth: `80px` }}><Monogram /></figure>
			</Link>
		</div>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header

